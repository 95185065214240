
import Podium from "../../assets/svg/podium.svg";
import teams from "../../api/json/teams";
import "./styles.scss";
import { useEffect, useState } from "react";
import currentWinners$ from "../../utils/observables/currentWinners$";
import ProgressItem from "../../api/models/Progress";

const PageWinners = () => {
  const [firstPlace, setFirstPlace] = useState({} as any);
  const [secondPlace, setSecondPlace] = useState({} as any);
  const [thirdPlace, setThirdPlace] = useState({} as any);

  useEffect(() => {
    if (currentWinners$.value.length > 0) {
      setFirstPlace(teams[(currentWinners$.value[0] as ProgressItem)?.name])
      setSecondPlace(teams[(currentWinners$.value[1] as ProgressItem)?.name])
      setThirdPlace(teams[(currentWinners$.value[2] as ProgressItem)?.name])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWinners$.value])

  return (
    <div className="page-winners">

      <div className="page-winners__team second">
        <div className="page-winners__img-container">
          <img src={secondPlace?.lead?.image} alt="road" className="page-winners__img second" />
        </div>
        <img src={Podium} alt="road" className="page-winners__podium second" />


        <h3 className="h3">{secondPlace.teamName}</h3>
        <h2 className="h2__winner">SECOND PLACE</h2>
      </div>

      <div className="page-winners__team first">

        <div className="page-winners__img-container">
          <img src={firstPlace?.lead?.image} alt="road" className="page-winners__img first" />
        </div>

        <img src={Podium} alt="road" className="page-winners__podium first" />


        <h3 className="h3">{firstPlace.teamName}</h3>
        <h2 className="h2__winner">FIRST PLACE</h2>
      </div>

      <div className="page-winners__team third">
        <div className="page-winners__img-container">
          <img src={thirdPlace?.lead?.image} alt="road" className="page-winners__img third" />
        </div>
        <img src={Podium} alt="road" className="page-winners__podium third" />


        <h3 className="h3">{thirdPlace.teamName}</h3>
        <h2 className="h2__winner">THIRD PLACE</h2>
      </div>

    </div>
  )
}

export default PageWinners;