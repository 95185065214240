import { Link, useLocation } from "react-router-dom";
import "../styles.scss";
import Winner from "../../../assets/svg/winner.svg";
import { useState, useEffect } from "react";
import isLoading$ from "../../../utils/observables/isLoading$";

const WinnerCTA = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname === "/winner") {
      setActive(true);
    }
  }, [location])

  return (
    <div className={`cta ${active ? "active" : ""}`}>
      <Link to="/winner" onClick={() => isLoading$.next(true)}>
        <img src={Winner} alt="Winner" className="cta__svg" />
      </Link>
    </div>
  )
}

export default WinnerCTA;