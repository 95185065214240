import React from "react";
import "./styles.scss";
import backgroundImg from "../../assets/images/background.png";

const Background = () => {
  return (
    <img src={backgroundImg} alt="Background" className="background" />
  )
}

export default Background;