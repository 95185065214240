import { Link, useLocation } from "react-router-dom";
import "../styles.scss";
import Team from "../../../assets/svg/teams.svg";
import { useState, useEffect } from "react";
import isLoading$ from "../../../utils/observables/isLoading$";

const TeamCTA = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname === "/teams") {
      setActive(true);
    }
  }, [location])

  return (
    <div className={`cta ${active ? "active" : ""}`}>
      <Link to="/teams" onClick={() => isLoading$.next(true)}>
        <img src={Team} alt="Team" className="cta__svg" />
      </Link>
    </div>
  )
}

export default TeamCTA;