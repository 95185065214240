import { FullPage } from "react-full-page";

import { useEffect } from "react";
import currentWinners$ from "../../utils/observables/currentWinners$";
import PageWinners from "../../modules/liveWinners/vPage";
import isLoading$ from "../../utils/observables/isLoading$";
import { useNavigate } from "react-router-dom";
import getProgress from "../../api/content/getProgress";
import ReportDetails from "../../api/models/ReportDetails";
import Sidebar from "../../modules/sidebar";

import "./styles.scss"

const Winner = () => {
  const navigate = useNavigate();

  const sortTeams = async () => {
    try {
      const results = await getProgress()

      // sort results by completion criteria
      const sortedResults = (results as any).allReports.sort((a: ReportDetails, b: ReportDetails) => {
        // if tests are equal, sort by code execution time
        if (((1 - ((a.failures + a.errors) / a.tests)) * 100) === (((1 - ((b.failures + b.errors) / b.tests)) * 100))) {
          return ((a.time - b.time))
        } else {
          // else sort by test failure + error rate
          return (((1 - ((a.failures + a.errors) / a.tests)) * 100) - (((1 - ((b.failures + b.errors) / b.tests)) * 100)))
        }
      })

      currentWinners$.next(sortedResults.slice(0, 3));
    } catch (err) {
      console.log("Get Progress Error, did you forget to VPN?", err);
      navigate("/")
    } finally {
      isLoading$.next(false);
    }
  }

  useEffect(() => {
    sortTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FullPage>
      <div className="winner">
        <Sidebar />
        <h1 className="h1__winner">
          Who's the winner?
        </h1>

        <PageWinners />
      </div>
    </FullPage>
  )
}

export default Winner;