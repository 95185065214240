import axios from "axios";

const get = async (endpoint: string) => {
  const baseURL = "https://fx-hackathon-junit-results.s3.eu-west-2.amazonaws.com/";

  const results = await axios.get(`${baseURL}${endpoint}`,
    {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
    });

  return results;
}

export default get;