import Progress from "../models/Progress";
import get from "./get";

const getProgress = async (): Promise<Array<Progress>> => {
  const results = await get("reports/combined-report.json");

  if (results && results.status === 200) {
    const { data } = results;

    return data;
  }

  return [];
}

export default getProgress;