import { Link, useLocation } from "react-router-dom";
import "../styles.scss";
import Live from "../../../assets/svg/live.svg";
import { useEffect, useState } from "react";
import isLoading$ from "../../../utils/observables/isLoading$";

const LiveCTA = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname === "/live") {
      setActive(true);
    }
  }, [location])

  return (
    <div className={`cta ${active ? "active" : ""}`}>
      <Link to="/live" onClick={() => isLoading$.next(true)}>
        <img src={Live} alt="Live" className="cta__svg" />
      </Link>
    </div>
  )
}

export default LiveCTA;