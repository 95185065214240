import { Link } from "react-router-dom";
import LiveCTA from "../../components/cta/live";
import TeamCTA from "../../components/cta/team";
import WinnerCTA from "../../components/cta/winner";
import isLoading$ from "../../utils/observables/isLoading$";

import "./styles.scss";

const Sidebar = () => {

  return (
    <div className="sidebar">
      <div className="sidebar__cta-container">
        <LiveCTA />
        <TeamCTA />
        <WinnerCTA />
      </div>
      <Link to="/" className="sidebar__strapline" onClick={() => isLoading$.next(true)}>
        <h2 className="h2">
          Hackathon
        </h2>
        <h4 className="h4 sidebar__subheading">
          Leaderboard
        </h4>
      </Link>
    </div>
  )
}

export default Sidebar;