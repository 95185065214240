import "./styles.scss";
import Sidebar from "../../modules/sidebar";
import { useEffect, useState } from "react";
import getTeams from "../../api/content/getTeams";
import Member from "../../components/member";
import { Member as MemberItem } from "../../api/models/Team";
import isLoading$ from "../../utils/observables/isLoading$";

const Teams = () => {
  const [teams, setTeams] = useState([] as Array<any>);

  useEffect(() => {
    const teams = getTeams();

    const arrayTeams = Object.entries(teams);

    setTeams(arrayTeams);
  }, [])

  const renderTeam = () => {
    return teams.map((team) => {
      return team.map((t: any) => {
        return Object.entries(t).map((el, index) => {
          if (typeof el === "object" && (el[1] as unknown as MemberItem).hasOwnProperty("name")) {
            isLoading$.next(false);

            return <Member member={el[1] as unknown as MemberItem} teamName={t.teamName} key={index} />
          }

          isLoading$.next(false);
          return null;
        })
      })
    })

  }

  return (
    <div className="teams">
      <Sidebar />
      <div className="teams__members">
        {renderTeam()}
      </div>
    </div>
  )
}

export default Teams;