import CompletionItem from "../../api/models/Completion";
import Lightning from "../../assets/svg/lightning.svg";

import "./styles.scss";

const Completion = (props: CompletionItem) => {
  const { completed } = props;

  return (
    <div className="completion">
      <div className="completion__filled" style={{ width: `${completed}%` }}>
        <img src={Lightning} alt="Lightning" className="completion__svg" />
        <div className="completion__label">
          <h5 className="h5">{Math.round(completed)}%</h5>
        </div>
      </div>
    </div>
  )
}

export default Completion;