import { Member as MemberItem } from "../../api/models/Team";

import "./styles.scss";

type MemberProps = {
  member: MemberItem;
  teamName: string;
}

const Member = (props: MemberProps) => {
  const { member, teamName } = props;
  const { name, image } = member;

  return (
    <div className="member">
      <div className="member__image">
        {image ? <img src={image} alt={name} /> :
          <img src="https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGV ufDB8fHx8&auto=format&fit=crop&w=2670&q=80" alt="Team" />
        }
      </div>
      <h4 className="h4">{name}</h4>
      <h5 className="h5">{teamName}</h5>
    </div>
  )
}

export default Member;