import Bell from "../../assets/svg/bell.svg";
import Countdown from "react-countdown";
import "./styles.scss";

const Timer = () => {

  const renderItem = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return null;
    } else {
      return (
        <>
          {days > 0 && <><div className="timer__boundary">
            <h4 className="h4">{days}</h4>
            <h5 className="h5">DAY</h5>
          </div><h4 className="h4">:</h4></>}
          <div className="timer__boundary">
            <h4 className="h4">{hours}</h4>
            <h5 className="h5">HRS</h5>
          </div>
          <h4 className="h4">:</h4>
          <div className="timer__boundary">
            <h4 className="h4">{minutes}</h4>
            <h5 className="h5">MINS</h5>

          </div>
          <h4 className="h4">:</h4>
          <div className="timer__boundary">
            <h4 className="h4">{seconds}</h4>
            <h5 className="h5">SECS</h5>
          </div>
        </>
      )
    }
  }

  return (
    <div className="timer">
      <img src={Bell} alt="Lightning" className="timer__svg" />

      <Countdown
        date={'2022-02-24T17:30:00'}
        renderer={renderItem}
      />
    </div>
  )
}

export default Timer;