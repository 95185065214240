import Team from "../models/Team";

const Teams = {
  "michael-json": {
    teamName: "Michael JSON",
    lead: { name: "Jack Rose", image: "https://media-exp1.licdn.com/dms/image/C4E03AQEv4gKsXr2SbQ/profile-displayphoto-shrink_800_800/0/1603275752424?e=1650499200&v=beta&t=mY3xczjJInp5KArXY0JehtsbIMrlHcjzi343Ke_B5n8" },
    dev1: { name: "Edo", image: "https://media-exp1.licdn.com/dms/image/C4D03AQGhjP7umPv1uQ/profile-displayphoto-shrink_800_800/0/1618843061292?e=1650499200&v=beta&t=8Dtqjl2QzeH71Y0mG6CpGy6oZEnoQ3-bYxgEVZ6zN4w" },
    dev2: { name: "Kate", image: "https://media-exp1.licdn.com/dms/image/C4E03AQGn6s42B_zX1g/profile-displayphoto-shrink_800_800/0/1641559141408?e=1650499200&v=beta&t=ghVRv6eZ7wbCiPU38h8zYVfIp8ZlFl3E0rKviJnvJ7o" },
    dev3: { name: "Derek", image: "https://media-exp1.licdn.com/dms/image/C5603AQHAa23CMdSJrQ/profile-displayphoto-shrink_800_800/0/1640056923782?e=1650499200&v=beta&t=bhnax_xPiBgWsuSH5unxqKcyJzR1Q0GBEwTh11vZCmc" },
    dev4: { name: "James Robins", image: "https://media-exp1.licdn.com/dms/image/C4D03AQGc8b01pAjsMQ/profile-displayphoto-shrink_800_800/0/1552667514412?e=1650499200&v=beta&t=TWd-sDPGjkgfq9ZaksmkMrAiC2mw9_AgwXalLCL_NZY" },
    qa1: { name: "Karol", image: "https://media-exp1.licdn.com/dms/image/C5603AQHKZPF38tD-hg/profile-displayphoto-shrink_800_800/0/1603722689442?e=1650499200&v=beta&t=NN3T_jMPYVpAhRmINj_eFrfnFUn7b3MgPxCSIM1MpE0" },
    qa2: { name: "Nixon", image: "https://media-exp1.licdn.com/dms/image/C4D03AQHhRC1oDeUXfA/profile-displayphoto-shrink_800_800/0/1640137137541?e=1650499200&v=beta&t=-v_B0ABdmu4A9lJJ9TLIH3BTrFZjT3dJjKXAMr8j00w" },
  },
  "team-peepeepoopoo": {
    teamName: "Team Marketing Appropriate Name",
    lead: { name: "Wis", image: "https://media-exp1.licdn.com/dms/image/C4E03AQG7CDHWcyWIog/profile-displayphoto-shrink_800_800/0/1645493522704?e=1651104000&v=beta&t=rtchralCF4Mg75CK6HK7j-EWcP3KOIKCL7vS8hNA1ro" },
    dev1: { name: "Joanna", image: "https://media-exp1.licdn.com/dms/image/C4E03AQHGaCw8DpLjWg/profile-displayphoto-shrink_800_800/0/1631540844391?e=1650499200&v=beta&t=BHrXve993AVbYZY-HVl9CDBjnHrpV9-FTWkpDP3jx7g" },
    dev2: { name: "Chris Fynes", image: "https://media-exp1.licdn.com/dms/image/C4D03AQFQ5W6A7UqUaw/profile-displayphoto-shrink_800_800/0/1631732451617?e=1650499200&v=beta&t=yX0Ajk822dgHRCiUmVIXuyxtd8Ay23e_V8_xQifrrAI" },
    dev3: { name: "Vink", image: "https://media-exp1.licdn.com/dms/image/C5603AQGkO-mNV20iCg/profile-displayphoto-shrink_800_800/0/1517371831612?e=1650499200&v=beta&t=XH7te2hInYlrr8bkcqTTQ2esEPTrP5r1GbsCwgt1ThQ" },
    qa1: { name: "Imad", image: "" },
    qa2: { name: "Davey", image: "https://media-exp1.licdn.com/dms/image/C4D03AQGu3DFzfBBAsA/profile-displayphoto-shrink_800_800/0/1631641432462?e=1650499200&v=beta&t=9CFKVZjHR4BAbrqQSmxplwluFaGNL0ObDmL2myJOdow" },
  },
  "team-tumbleweed": {
    teamName: "Team Tumbleweed",
    lead: { name: "Ola", image: "https://media-exp1.licdn.com/dms/image/C4D03AQGtp6OnxByugw/profile-displayphoto-shrink_800_800/0/1533566577691?e=1650499200&v=beta&t=UfBjoaeOpR642P9HcnhvtNuWs0BG8bqVu5TJVgjJ0UE" },
    dev1: { name: "Mark", image: "https://media-exp1.licdn.com/dms/image/C4E03AQGxmuaywwrUGQ/profile-displayphoto-shrink_800_800/0/1644252550991?e=1650499200&v=beta&t=8O0EvAuVUWb3_8OEmpFH4xe8rRzQfpSFKzxXyIgOcMc" },
    dev2: { name: "Nuh", image: "https://media-exp1.licdn.com/dms/image/C4D03AQGJ669sSYeB0A/profile-displayphoto-shrink_800_800/0/1605547542546?e=1650499200&v=beta&t=ZM7JPumZK9X_zujEqzO6Kq2TWFfG1F0OhM4mOoK-f7o" },
    dev3: { name: "Zayd", image: "https://media-exp1.licdn.com/dms/image/C4E03AQGPfVjnrtm6jA/profile-displayphoto-shrink_800_800/0/1610604294286?e=1650499200&v=beta&t=BuVxDIA4KZz8euFFZhZw032Tk75N-a4qwMfNnNpsfPg" },
    qa3: { name: "Gosney", image: "https://media-exp1.licdn.com/dms/image/C4E03AQH0_Ex0PejxEQ/profile-displayphoto-shrink_800_800/0/1631801295077?e=1650499200&v=beta&t=-H1X1WDPKIDTICmOyxMC-RvtewATbSKwXeV0H4CaLuY" },
  },
  "simply-red-gex": {
    teamName: "Simply Red-gex",
    lead: { name: "Giorgio", image: "https://media-exp1.licdn.com/dms/image/C5603AQH2dqQjPywb-Q/profile-displayphoto-shrink_800_800/0/1516614128924?e=1650499200&v=beta&t=jo0S1S0RpVPg4XGbWB7ij-vGPce7x7WVN0HA82hT8Vs" },
    dev1: { name: "Michael Simmonds", image: "https://media-exp1.licdn.com/dms/image/C4E03AQFazJSa6C28xg/profile-displayphoto-shrink_800_800/0/1552419923174?e=1651104000&v=beta&t=caE4L5nDUSdaZMXfZXQEYvyH4sIrmeBDNsPn_BBjkU4" },
    dev2: { name: "Ed Hughes", image: "https://media-exp1.licdn.com/dms/image/C4D03AQGV2TJgFcjDLA/profile-displayphoto-shrink_800_800/0/1620832519493?e=1650499200&v=beta&t=DSn6NQEhfMsv1XqkV7BoN8HaEIsESy1K-czMeHnXzJ8" },
    dev3: { name: "MJ", image: "https://media-exp1.licdn.com/dms/image/C5603AQGHFxBt8pGI2g/profile-displayphoto-shrink_800_800/0/1618120086124?e=1650499200&v=beta&t=J7CDKv0e31Jot_tEu3B5LgVUQFfe1sOuIdmcUqlAfmg" },
    qa2: { name: "Michael Sobowale", image: "" },
    qa3: { name: "Welch", image: "https://i.ibb.co/2NwjXfd/image.png" },
  },
  "promise-reject-wearing-pants": {
    teamName: "Promise.reject(\"wearingPants\")",
    lead: { name: "Poncho", image: "https://media-exp1.licdn.com/dms/image/C4E03AQEKBfwsTYrScA/profile-displayphoto-shrink_800_800/0/1620911693564?e=1650499200&v=beta&t=UZ2ArNWR-DE2VP5olQjj1ePZyOH2NEMdCn3HeqraNng" },
    dev1: { name: "David Mitjana", image: "https://media-exp1.licdn.com/dms/image/C5603AQFsSoSnqRX74g/profile-displayphoto-shrink_800_800/0/1536598330837?e=1650499200&v=beta&t=8iSB8KuFHumZwCLRliyOKKhK5xhrNFaHDPOApp5jSHo" },
    dev2: { name: "Sebas", image: "https://ca.slack-edge.com/T04E9ACE6-U01LAGPU4KG-7378024a3af3-512" },
  },
} as Team;

export default Teams;