import React from "react";
import laptopImg from "../../assets/images/laptop.png";
import LiveCTA from "../../components/cta/live";
import WinnerCTA from "../../components/cta/winner";
import TeamCTA from "../../components/cta/team";
import "./styles.scss";

const Hero = () => {

  return (
    <div className="hero">
      <div className="hero__container">
        <img src={laptopImg} alt="Laptop" className="hero__background" />

        <h1 className="h1">Hackathon</h1>
        <h2 className="h2">Leaderboard</h2>

        <div className="hero__container-row">
          <div className="hero__cta-spacer">
            <WinnerCTA />
          </div>
          <div className="hero__cta-spacer">
            <LiveCTA />
          </div>
          <TeamCTA />
        </div>
      </div>
    </div>
  )
}

export default Hero;