
import Road from "../../assets/svg/road.svg";
import Podium from "../../assets/svg/podium.svg";
import teams from "../../api/json/teams";
import "./styles.scss";
import { useEffect, useState } from "react";
import currentWinners$ from "../../utils/observables/currentWinners$";
import ProgressItem from "../../api/models/Progress";

const LiveWinners = () => {
  const [firstPlace, setFirstPlace] = useState("");
  const [secondPlace, setSecondPlace] = useState("");
  const [thirdPlace, setThirdPlace] = useState("");

  useEffect(() => {
    if (currentWinners$.value.length > 0) {
      setFirstPlace(teams[(currentWinners$.value[0] as ProgressItem).name].lead.image)
      setSecondPlace(teams[(currentWinners$.value[1] as ProgressItem)?.name].lead.image)
      setThirdPlace(teams[(currentWinners$.value[2] as ProgressItem)?.name].lead.image)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWinners$.value])

  return (
    <div className="live-winners">
      <img src={Road} alt="road" className="live-winners__road" />

      <div className="live-winners__team third">
        <div className="live-winners__img-container">
          <img src={thirdPlace} alt="road" className="live-winners__img third" />
        </div>
        <img src={Podium} alt="road" className="live-winners__podium third" />
      </div>

      <div className="live-winners__team second">
        <div className="live-winners__img-container">
          <img src={secondPlace} alt="road" className="live-winners__img second" />
        </div>
        <img src={Podium} alt="road" className="live-winners__podium second" />

      </div>

      <div className="live-winners__team first">

        <div className="live-winners__img-container">
          <img src={firstPlace} alt="road" className="live-winners__img first" />
        </div>

        <img src={Podium} alt="road" className="live-winners__podium first" />
      </div>

      <div className="live-winners__strapline">
        <h3 className="h3">
          <strong>Who's</strong> winning?
        </h3>
      </div>
    </div>
  )
}

export default LiveWinners;