import Hero from "../../modules/hero/index";
import { FullPage, Slide } from "react-full-page";
import Logo from "../../assets/svg/logo.svg";

import isLoading$ from "../../utils/observables/isLoading$";

import "./styles.scss";

const Cover = () => {
  return (
    <FullPage>
      <Slide>
        <Hero />
        <div className="cover__logo">
          <img src={Logo} alt="Logo" className="cover__logo-img" onLoad={() => isLoading$.next(false)} />
        </div>
      </Slide>
    </FullPage>
  )
}

export default Cover;