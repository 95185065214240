import { FullPage } from "react-full-page";

import { useNavigate } from "react-router-dom";

import "./styles.scss";
import Sidebar from "../../modules/sidebar";
import { useEffect, useState } from "react";
import getProgress from "../../api/content/getProgress";
import ProgressItem from "../../api/models/Progress";
import Progress from "../../modules/progress";
import Timer from "../../modules/timer";
import currentWinners$ from "../../utils/observables/currentWinners$";
import LiveWinners from "../../modules/liveWinners";
import isLoading$ from "../../utils/observables/isLoading$";
import ReportDetails from "../../api/models/ReportDetails";

const Live = () => {
  const [progress, setProgress] = useState([] as Array<ProgressItem>);
  const navigate = useNavigate();

  const getTeams = async () => {
    try {
      const results = await getProgress()

      // sort results by completion criteria
      const sortedResults = (results as any).allReports.sort((a: ReportDetails, b: ReportDetails) => {
        // if tests are equal, sort by code execution time
        if (((1 - ((a.failures + a.errors) / a.tests)) * 100) === (((1 - ((b.failures + b.errors) / b.tests)) * 100))) {
          return ((a.time - b.time))
        } else {
          // else sort by test failure + error rate
          return (((1 - ((a.failures + a.errors) / a.tests)) * 100) - (((1 - ((b.failures + b.errors) / b.tests)) * 100)))
        }
      })

      currentWinners$.next(sortedResults.slice(0, 3));

      setProgress(sortedResults);
    } catch (err) {
      console.log("Get Progress Error, did you forget to VPN?", err);
      navigate("/")
    } finally {
      isLoading$.next(false);
    }
  }

  useEffect(() => {
    getTeams();

    const timer = setInterval(() => {
      getTeams()
    }, 10000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FullPage className="live">
      <Sidebar />
      <div className="live__progress">
        {progress && progress.map((item, index) =>
          <Progress key={index} {...item} />)
        }
      </div>
      <Timer />
      <LiveWinners />
    </FullPage>
  )
}

export default Live;