import React, { useState } from 'react';
import './App.scss';
import Background from './components/background';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import LoadingScreen from "react-loading-screen";

import isLoading$ from "./utils/observables/isLoading$"

import Cover from "./pages/cover/index";
import Live from './pages/live';
import Teams from './pages/teams';
import Winner from './pages/winner';

import Logo from "./logo.png"

function App() {
  const [isLoaderVisible, setLoaderVisible] = useState(true);

  isLoading$.subscribe((isVisible) => {
    if (isVisible !== isLoaderVisible) {
      setLoaderVisible(isVisible);
    }
  });

  return (
    <div className="app">
      <LoadingScreen
        loading={isLoaderVisible}
        bgColor='#EB0042'
        spinnerColor='#C4C4C4'
        textColor='#C4C4C4'
        logoSrc={Logo}
      >
        <Background />
        <Router>
          <Routes>
            <Route path="/" element={<Cover />} />
            <Route path="/live" element={<Live />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/winner" element={<Winner />} />
          </Routes>
        </Router>
      </LoadingScreen>
    </div>
  );
}

export default App;
