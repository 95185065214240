import { useEffect, useState } from "react";
import ProgressItem from "../../api/models/Progress";
import TeamItem, { Member as MemberItem } from "../../api/models/Team";
import Completion from "../../components/completion";
import teams from "../../api/json/teams";

import "./styles.scss";

const Progress = (props: ProgressItem) => {
  const { name, tests, failures, errors, time } = props;

  const [team, setTeam] = useState({} as TeamItem)
  const [imageArray, setImageArray] = useState([] as Array<string>)
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    if (teams[name]) {
      setTeam(teams[name] as unknown as TeamItem)
    }
  }, [name])

  useEffect(() => {
    if (team) {
      const tempImages: Array<string> = []

      Object.entries(team).forEach(el => {
        if (typeof el[1] === "object") {
          if ((el[1] as unknown as MemberItem).image) {
            tempImages.push((el[1] as unknown as MemberItem).image)
          }
        }
      })

      setImageArray(tempImages);
    }
  }, [team])

  useEffect(() => {
    const timer = setInterval(() => {
      setImageIndex(prevImageIndex => (imageIndex === imageArray.length ? 0 : prevImageIndex + 1));
    }, 12000);
    return () => {
      clearInterval(timer);
    };
  }, [imageArray.length, imageIndex]);

  return (
    <div className="progress">
      <div className="progress__img">
        {imageArray[imageIndex] ? <img src={imageArray[imageIndex]} alt="Team" key={imageIndex} /> :
          <img src="https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGV ufDB8fHx8&auto=format&fit=crop&w=2670&q=80" alt="Team" key={imageIndex} />
        }
      </div>
      <div className="progress__content">
        <Completion completed={((1 - ((failures + errors) / tests)) * 100)} />
        <h5 className="h5">CODE EXECUTION TIME (seconds): {time}</h5>
        <h4 className="h4">{team.teamName}</h4>
      </div>
    </div>
  )
}

export default Progress;